<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-globe2 fs-3 text-primary"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>{{ToChucService.formData.Name}}</b></h2>
    </div>
</div>
<div class="app-body">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div id="map" style="height: 800px; width: 100%;">
                <div style="z-index: 1000; position: absolute; top: 0;">
                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                </div>
                <div
                    style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>