import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';

import { interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CRM';
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;
  queryString: string = environment.InitializationString;
  queryStringSub: string = environment.InitializationString;
  constructor(
    public router: Router,
    public DownloadService: DownloadService,
    public ThanhVienService: ThanhVienService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public ThanhVienLichSuTruyCapService: ThanhVienLichSuTruyCapService,

  ) {
    this.getByQueryString();
  }
  getByQueryString() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.indexOf(environment.Token) > -1) {
          localStorage.setItem(environment.Token, this.queryString.split('=')[this.queryString.split('=').length - 1]);
        }
        this.AuthenticationToken();
      }
    });
  }
  AuthenticationToken() {
    let token = localStorage.getItem(environment.Token);
    let isLogin = true;
    if (token == null) {
      isLogin = false;
    }
    else {
      this.ThanhVienTokenService.baseParameter.Token = token;
      this.ThanhVienTokenService.AuthenticationByTokenAsync().subscribe(
        res => {
          this.ThanhVienTokenService.formData = res as ThanhVienToken;
          if (this.ThanhVienTokenService.formData != null) {
            if (this.ThanhVienTokenService.formData.ParentID > 0) {              
              this.ThanhVienService.baseParameter.ID=this.ThanhVienTokenService.formData.ParentID;
              this.ThanhVienService.GetByIDAsync().subscribe(
                res => {
                  this.ThanhVienService.formDataLogin = res as ThanhVien;
                  if (this.ThanhVienService.formDataLogin) {
                    if (this.ThanhVienService.formDataLogin.ParentID == null) {
                      this.ThanhVienService.formDataLogin.ParentID = environment.InitializationNumber;
                    }
                    localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.formDataLogin.ID.toString());
                    localStorage.setItem(environment.ThanhVienParentID, this.ThanhVienService.formDataLogin.ParentID.toString());
                    localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.formDataLogin.TaiKhoan);
                    localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.formDataLogin.Name);
                    localStorage.setItem(environment.ThanhVienFileName, this.ThanhVienService.formDataLogin.FileName);      
                    this.ThanhVienLichSuTruyCapSaveAsync(this.queryString);             
                  }
                  else {
                    isLogin = false;
                  }
                },
                err => {
                  isLogin = false;
                }
              );
            }
            else {
              isLogin = false;
            }
          }
          else {
            isLogin = false;
          }
        },
        err => {
          isLogin = false;
        }
      );
    }
    if (isLogin == false) {
      let destinationURL = environment.LoginURL + "?url=" + environment.DomainURL;
      window.location.href = destinationURL;
    }
  }
  ThanhVienLichSuTruyCapSaveAsync(url: string) {
    url = environment.DomainURL + "#" + url;
    this.ThanhVienLichSuTruyCapService.formData.URL = url;
    this.ThanhVienLichSuTruyCapService.formData.Name = this.ThanhVienService.formDataLogin.Name;
    this.ThanhVienLichSuTruyCapService.formData.Code = this.ThanhVienService.formDataLogin.TaiKhoan;
    this.ThanhVienLichSuTruyCapService.formData.Token = localStorage.getItem(environment.Token);
    this.ThanhVienLichSuTruyCapService.formData.ParentID = this.ThanhVienService.formDataLogin.ID;    
    this.DownloadService.GetIPAddress().then(res => {
      this.DownloadService.IPAddress = res["ip"];
      this.ThanhVienLichSuTruyCapService.formData.Description = this.DownloadService.IPAddress;     
      this.ThanhVienLichSuTruyCapService.SaveAsync().subscribe(
        res => {
        },
        err => {
        }
      );
    }).catch(error => {
      this.ThanhVienLichSuTruyCapService.SaveAsync().subscribe(
        res => {
        },
        err => {
        }
      );
    });
  }
  Logout() {
    localStorage.setItem(environment.Token, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienID, environment.InitializationString);
    window.location.href = environment.LoginURL;
  }
}
