<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-gear fs-3 text-primary"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>Trục dữ liệu lớn Nông nghiệp Đồng Bằng Sông Cửu Long</b></h2>
        <h6 class="m-0 text-dark fw-light">
            Đề án phát triển 1 triệu ha lúa chất lượng cao, phát thải thấp gắn với tăng trưởng xanh
        </h6>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThongBaoDetail" data-bs-toggle="tab"
                                    href="#ThongBaoDetail" role="tab" aria-controls="ThongBaoDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hướng dẫn sử dụng</span>
                                </a>
                            </li>

                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThongBaoDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>1. Công cụ lấy tọa độ Hợp tác xã và Vùng trồng: <a
                                                        href="https://toado.vungtrong.com/" class="link-primary" style="font-size: 30px;"
                                                        target="_blank">toado.vungtrong.com</a></b></h3>
                                        </div>
                                        <hr />
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>2. Hướng dẫn HTX Nông Nghiệp và Thành viên HTX tạo bản đồ vùng
                                                    trồng</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/NV7aDoCAp3E?si=Dea59YYeoGHsyUwk"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3><b>3. Viễn thám thời tiết theo dữ liệu vệ tinh (Readtime)</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <iframe width="100%" height="600"
                                                src="https://www.youtube.com/embed/y_pd9B-hz8I?si=he_TrmTD7mAVdgRI"
                                                title="YouTube video player" frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>