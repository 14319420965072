<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-bar-chart-fill fs-3 text-primary"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>Báo cáo tổng hợp</b></h2>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase; font-size: 14px;">Số lượng Hợp tác xã đã khảo
                    sát tham gia đề án giai đoạn 2023-2025
                </h4>
                <canvas baseChart [datasets]="ChartDataReport1002SoLuongKhaoSat"
                    [labels]="ChartLabelsReport1002SoLuongKhaoSat" [chartType]="ChartTypeReport1002SoLuongKhaoSat"
                    [colors]="ChartColorsReport1002SoLuongKhaoSat" [options]="ChartOptionsReport1002SoLuongKhaoSat">
                </canvas>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase; font-size: 14px;">Diện tích Hợp tác xã đã khảo
                    sát tham gia đề án giai đoạn 2023-2025
                </h4>
                <canvas style="font-size: 14px;" baseChart [datasets]="ChartDataReport1002DienTichKhaoSat"
                    [labels]="ChartLabelsReport1002DienTichKhaoSat" [chartType]="ChartTypeReport1002DienTichKhaoSat"
                    [colors]="ChartColorsReport1002DienTichKhaoSat" [options]="ChartOptionsReport1002DienTichKhaoSat">
                </canvas>
            </div>
        </div>
    </div>    
    <div class="row gx-4">
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase; font-size: 14px;">Số lượng và Diện tích Hợp
                    tác xã
                    đã khảo sát tham gia đề án giai đoạn 2023-2025</h4>
                <canvas style="font-size: 14px;" baseChart [datasets]="ChartDataReport1002"
                    [labels]="ChartLabelsReport1002" [chartType]="ChartTypeReport1002" [colors]="ChartColorsReport1002"
                    [options]="ChartOptionsReport1002">
                </canvas>
            </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-12">
            <div style="border-color: #000000; border-width: 1px; border-style: solid; padding: 10px;">
                <h4 class="text-center" style="text-transform: uppercase; font-size: 14px;">Số lượng và Diện tích Hợp
                    tác xã
                    đã khảo sát tham gia đề án giai đoạn 2023-2025</h4>
                <div class="table-responsive">
                    <table class="table m-0 align-middle" style="font-size: 12px;">
                        <thead>
                            <tr>
                                <th style="text-align: center;">STT</th>
                                <th style="text-align: center;">Tỉnh thành</th>
                                <th style="text-align: center;">Số lượng</th>
                                <th style="text-align: center;">Diện tích (ha)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of ReportService.list1002; index as i;">
                                <td style="text-align: center;">
                                    {{i + 1}}
                                </td>
                                <td>
                                    <b>{{item.DanhMucTinhThanhName}}</b>
                                </td>
                                <td style="text-align: right;">
                                    {{item.SoLuongKhaoSat |
                                    number:
                                    '1.0-0'}} ({{item.TyLeHoanThanhSoLuong}}%)
                                </td>
                                <td style="text-align: right;">
                                    {{item.DienTichKhaoSat |
                                    number:
                                    '1.0-2'}} ({{item.TyLeHoanThanhDienTich}}%)
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
    <div class="row gx-4">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Hợp tác xã đăng ký</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.SoLuongThamGia |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Chi tiết đến xã phường</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Hợp tác xã khảo sát</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Giai đoạn 2023-2025</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Diện tích đăng ký</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">> {{ReportService.formData.DienTichThamGia |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Đơn vị tính: ha</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Diện tích khảo sát</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">> {{ReportService.formData.DienTichKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Đơn vị tính: ha</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-primary">
                <div class="card-header">
                    <h5 class="card-title text-white bg-primary">Vùng trồng khảo sát</h5>
                </div>
                <div class="card-body bg-primary">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.VungTrongKhaoSat |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Tọa độ HTX cung cấp</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-dark">
                <div class="card-header">
                    <h5 class="card-title text-white">Nông dân</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">{{ReportService.formData.ThanhVienThamGia |
                                number:
                                '1.0-0'}}</h2>
                            <h5 class="mb-3 fw-light opacity-75">Hội viên tham gia HTX</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>