import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-chuc-info',
  templateUrl: './to-chuc-info.component.html',
  styleUrls: ['./to-chuc-info.component.css']
})
export class ToChucInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
