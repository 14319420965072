import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

@Component({
  selector: 'app-map-vung-trong-info',
  templateUrl: './map-vung-trong-info.component.html',
  styleUrls: ['./map-vung-trong-info.component.css']
})
export class MapVungTrongInfoComponent implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  domainURL = environment.DomainURL;
  queryString: string = environment.InitializationString;

  icon: string = environment.MapIcon;
  latitude: number = 9.967757;
  longitude: number = 105.595843;
  display: any;
  center: google.maps.LatLngLiteral = { lat: this.latitude, lng: this.longitude };
  zoom = 14;
  map: any;
  markerOptions: any = {
    draggable: false,
    icon: this.icon,
  };
  markers: any = [];
  infoWindows: any = [];
  constructor(
    public router: Router,
    public ToChucService: ToChucService,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.ToChucService.formData.ID = parseInt(this.queryString.split("/")[2]);
        if (this.queryString) {
          if (this.queryString.length > 0) {
            this.MapLoad();
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

  MapLoad() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.IDString = this.queryString
    this.ToChucService.GetByIDStringAsync().subscribe(res => {
      this.ToChucService.formData = res as ToChuc;
      if (this.ToChucService.formData) {
        this.ToChucService.baseParameter.ID = this.ToChucService.formData.ID;
        this.ToChucService.GetSQLByToChucToaDoByIDToListAsync().subscribe(res => {
          this.ToChucService.list1003 = res as ToChuc[];
          if (this.ToChucService.list1003) {
            if (this.ToChucService.list1003.length > 0) {
              let listToChucToaDoActive = [];
              let listToChucToaDoActiveNot = [];
              for (let i = 0; i < this.ToChucService.list1003.length; i++) {
                if (this.ToChucService.list1003[i].Active == true) {
                  listToChucToaDoActive.push(this.ToChucService.list1003[i]);
                }
              }
              if (listToChucToaDoActive) {
                if (listToChucToaDoActive.length > 0) {
                  this.latitude = Number(listToChucToaDoActive[0].ViDo);
                  this.longitude = Number(listToChucToaDoActive[0].KinhDo);
                  this.map = new google.maps.Map(document.getElementById("map"), {
                    center: { lat: this.latitude, lng: this.longitude },
                    zoom: this.zoom,
                    mapTypeId: 'hybrid',
                  });
                  this.map.addListener("zoom_changed", () => {
                    let zoomLevel = this.map.getZoom();
                    this.markerOptions = {
                      draggable: false,
                      icon: this.icon,
                    };
                  });

                  let contentString = '<div>';
                  contentString = contentString + '<h1 style="cursor: pointer; font-size: 16px;"><b>' + listToChucToaDoActive[0].Name + '</b></h1>';                 
                  contentString = contentString + '</div>';

                  let infowindow = new google.maps.InfoWindow({
                    content: contentString,
                  });
                  this.infoWindows.push(infowindow);

                  let marker = new google.maps.Marker({
                    title: listToChucToaDoActive[0].Name,
                    position: { lat: Number(listToChucToaDoActive[0].ViDo), lng: Number(listToChucToaDoActive[0].KinhDo) },
                    map: this.map,
                    icon: this.icon,
                  });
                  marker.addListener("click", () => {
                    for (let j = 0; j < this.infoWindows.length; j++) {
                      let infowindow = this.infoWindows[j];
                      infowindow.close(this.map, this.markers[j])
                    }
                    infowindow.open(this.map, marker);
                  });
                  marker.addListener("mouseover", () => {
                    for (let j = 0; j < this.infoWindows.length; j++) {
                      let infowindow = this.infoWindows[j];
                      infowindow.close(this.map, this.markers[j])
                    }
                    infowindow.open(this.map, marker);
                  });
                  this.markers.push(marker);


                  for (let i = 0; i < this.ToChucService.list1003.length; i++) {
                    if (this.ToChucService.list1003[i].Active == false) {
                      listToChucToaDoActiveNot.push(this.ToChucService.list1003[i]);
                    }
                  }

                  let listToChucToaDoPolygon = [];
                  let code = '';
                  for (let i = 0; i < listToChucToaDoActiveNot.length; i++) {

                    let markerTitle = "" + listToChucToaDoActiveNot[i].DanhMucNgonNguID.toString();

                    let marker = new google.maps.Marker({
                      title: markerTitle,
                      position: { lat: Number(listToChucToaDoActiveNot[i].ViDo), lng: Number(listToChucToaDoActiveNot[i].KinhDo) },
                      map: this.map,
                      icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: "white",
                        strokeColor: "white",
                        scale: 3
                      }
                    });
                    this.markers.push(marker);

                    if (code != listToChucToaDoActiveNot[i].Code) {
                      if (listToChucToaDoPolygon.length > 0) {
                        let polygon = new google.maps.Polygon({
                          paths: listToChucToaDoPolygon,
                          strokeColor: "#FF0000",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#FF0000",
                          fillOpacity: 0.35,
                        });
                        polygon.setMap(this.map);
                      }
                      listToChucToaDoPolygon = [];
                      code = listToChucToaDoActiveNot[i].Code;
                    }

                    listToChucToaDoPolygon.push({ lat: Number(listToChucToaDoActiveNot[i].ViDo), lng: Number(listToChucToaDoActiveNot[i].KinhDo) });

                    if (i == listToChucToaDoActiveNot.length - 1) {
                      let polygon = new google.maps.Polygon({
                        paths: listToChucToaDoPolygon,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35,
                      });
                      polygon.setMap(this.map);
                    }
                  }
                }
              }
            }
          }
        });
      }
      this.isShowLoading = false;
    },
      err => {
        this.isShowLoading = false;
      });
  }
}