import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucSanPhamGiong } from 'src/app/shared/DanhMucSanPhamGiong.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucSanPhamGiongService extends BaseService{

    list: DanhMucSanPhamGiong[] | undefined;
    list001: DanhMucSanPhamGiong[] | undefined;
    formData!: DanhMucSanPhamGiong;

    displayColumns001: string[] = ['STT', 'ID', 'Code', 'TypeName', 'Name', 'Display', 'NgayHieuLuc', 'Note', 'IsBaoHo', 'Save'];    
    displayColumns002: string[] = ['STT'];    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucSanPhamGiong";
    }
}

