import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienService extends BaseService {

    list: ThanhVien[] | undefined;
    list001: ThanhVien[] | undefined;
    formData!: ThanhVien;
    formDataLogin!: ThanhVien;

    list002: ThanhVien[] | undefined;
    list003: ThanhVien[] | undefined;
    listChuyenGia: ThanhVien[] | undefined;
    listChuyenVien: ThanhVien[] | undefined;

    displayColumns001: string[] = ['STT', 'Name', 'TaiKhoan', 'MatKhau', 'Email', 'TypeName', 'Active', 'Save'];    
    displayColumns002: string[] = ['STT', 'DanhMucTinhThanhName', 'Description', 'Note', 'Name', 'TaiKhoan', 'Email', 'Active'];
    displayColumns003: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'Name', 'TaiKhoan', 'Email', 'Active'];
    displayColumns004: string[] = ['STT'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVien";
        this.GetLogin();
    }

    GetLogin() {
        this.formDataLogin = {
        }
        this.formDataLogin.Name = localStorage.getItem(environment.ThanhVienHoTen);        
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.formDataLogin.ID = Number(lastUpdatedMembershipID);
        }
        var thanhVienParentID = localStorage.getItem(environment.ThanhVienParentID);
        if (thanhVienParentID) {
            this.formDataLogin.ParentID = Number(thanhVienParentID);
        }
    }

    GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

