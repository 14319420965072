import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';

import { NotificationService } from './shared/Notification.service';


import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { MapComponent } from './map/map.component';
import { MapVungTrongComponent } from './map-vung-trong/map-vung-trong.component';
import { MapVungTrongInfoComponent } from './map-vung-trong-info/map-vung-trong-info.component';
import { ToChucInfoComponent } from './to-chuc-info/to-chuc-info.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HopTacXaComponent } from './hop-tac-xa/hop-tac-xa.component';
import { HopTacXaInfoComponent } from './hop-tac-xa-info/hop-tac-xa-info.component';
import { MapHopTacXaComponent } from './map-hop-tac-xa/map-hop-tac-xa.component';
import { ThongKeComponent } from './thong-ke/thong-ke.component';
import { HuongDanSuDungComponent } from './huong-dan-su-dung/huong-dan-su-dung.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { TuVanComponent } from './tu-van/tu-van.component';
import { ThanhVienCoQuanQuanLyDetailComponent } from './thanh-vien-co-quan-quan-ly-detail/thanh-vien-co-quan-quan-ly-detail.component';
import { DanhMucSanPhamGiongDetailComponent } from './danh-muc-san-pham-giong-detail/danh-muc-san-pham-giong-detail.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';




@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
        HomepageComponent,
        MapComponent,
        MapVungTrongComponent,
        MapVungTrongInfoComponent,
        ToChucInfoComponent,
        DashboardComponent,
        HopTacXaComponent,
        HopTacXaInfoComponent,
        MapHopTacXaComponent,
        ThongKeComponent,
        HuongDanSuDungComponent,
        GioiThieuComponent,
        TuVanComponent,
        ThanhVienCoQuanQuanLyDetailComponent,
        DanhMucSanPhamGiongDetailComponent,
        ThanhVienThongTinComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        GoogleMapsModule,
        ChartsModule,
        CKEditorModule,
    ],
    providers: [
        CookieService,
        NotificationService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
