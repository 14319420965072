<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-search fs-3 text-primary" (click)="SetIsShowSearch()" style="cursor: pointer;"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>Hợp tác xã</b></h2>
    </div>
</div>
<div class="app-body">
    <div class="row" *ngIf="isShowSearch">
        <div class="col-lg-2 col-sm-6 col-12">
            <label class="form-label">Tỉnh thành</label>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
            <mat-select class="form-control" name="DanhMucTinhThanhID"
                [(ngModel)]="this.ToChucService.baseParameter.DanhMucTinhThanhID">
                <input placeholder="Tìm..." class="form-control" (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                <mat-option [value]="0">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <input [(ngModel)]="this.ToChucService.baseParameter.SearchString" placeholder="Tìm ..." (keyup.enter)="MapLoad()"
                class="form-control">
        </div>
        <div class="col-lg-1 col-sm-12 col-12">
            <div>
                <a class="btn btn-success" title="Tìm..." style="width: 100%;" (click)="MapLoad()"><i
                        class="bi bi-search"></i></a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">            
            <div class="map" #map style="height: 840px; width: 100%; position: relative;">
                <div style="z-index: 1000; position: absolute; top: 0;">
                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                </div>
                <div
                    style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>