<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-gear fs-3 text-primary"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>Chuyên gia + Giống + BVTV</b></h2>
        <h6 class="m-0 text-dark fw-light">
            Tư vấn
        </h6>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThanhVienChuyenVien" data-bs-toggle="tab"
                                    href="#ThanhVienChuyenVien" role="tab" aria-controls="ThanhVienChuyenVien"
                                    aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Chuyên
                                        viên</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienChuyenGia" data-bs-toggle="tab"
                                    href="#ThanhVienChuyenGia" role="tab" aria-controls="ThanhVienChuyenGia"
                                    aria-selected="true">
                                    <span class="badge bg-primary" style="font-size: 16px;">Chuyên
                                        gia</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucNguyenVatLieu" data-bs-toggle="tab"
                                    href="#DanhMucNguyenVatLieu" role="tab" aria-controls="DanhMucNguyenVatLieu"
                                    aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 16px;">Thuốc BVTV</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucSanPhamGiong" data-bs-toggle="tab"
                                    href="#DanhMucSanPhamGiong" role="tab" aria-controls="DanhMucSanPhamGiong"
                                    aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Giống</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade" id="ThanhVienChuyenVien" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="DanhMucTinhThanhID"
                                            [(ngModel)]="DanhMucTinhThanhID">
                                            <option *ngFor="let item of DanhMucTinhThanhService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ChuyenVienSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">

                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ChuyenVienSearch()"
                                                style="width: 100%;"><i class="bi bi-search"></i></a>
                                            <!-- <a class="btn btn-success" title="Thêm mới" (click)="ChuyenGiaAdd(0)"><i
                                                    class="bi bi-plus"></i></a> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Chuyên viên
                                            <span
                                                *ngIf="ThanhVienService.listChuyenVien">({{ThanhVienService.listChuyenVien.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienChuyenVienSort="matSort"
                                                    [dataSource]="ThanhVienChuyenVienDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thông tin chi tiết
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: left;">
                                                            Họ tên: <b>{{element.Name}}</b>
                                                            <br />
                                                            Điện thoại: <b>{{element.TaiKhoan}}</b>
                                                            <br />
                                                            Email: <b>{{element.Email}}</b>
                                                            <br />
                                                            Chức danh: <b>{{element.Note}}</b>
                                                            <br />
                                                            Đơn vị: <b>{{element.Description}}</b>
                                                            <br />
                                                            Tỉnh thành: <b>{{element.DanhMucTinhThanhName}}</b>
                                                        </td>
                                                    </ng-container>                                                 
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienService.displayColumns004">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienService.displayColumns004;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienChuyenVienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" id="ThanhVienChuyenGia" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="DanhMucTinhThanhID"
                                            [(ngModel)]="DanhMucTinhThanhID">
                                            <option *ngFor="let item of DanhMucTinhThanhService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <input [(ngModel)]="ThanhVienService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ChuyenGiaSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">

                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ChuyenGiaSearch()"
                                                style="width: 100%;"><i class="bi bi-search"></i></a>
                                            <!-- <a class="btn btn-success" title="Thêm mới" (click)="ChuyenGiaAdd(0)"><i
                                                    class="bi bi-plus"></i></a> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Chuyên gia
                                            <span
                                                *ngIf="ThanhVienService.listChuyenGia">({{ThanhVienService.listChuyenGia.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienChuyenGiaSort="matSort"
                                                    [dataSource]="ThanhVienChuyenGiaDataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thông tin chi tiết
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: left;">
                                                            Họ tên: <b>{{element.Name}}</b>
                                                            <br />
                                                            Điện thoại: <b>{{element.TaiKhoan}}</b>
                                                            <br />
                                                            Email: <b>{{element.Email}}</b>
                                                            <br />
                                                            Chức danh: <b>{{element.Note}}</b>
                                                            <br />
                                                            Đơn vị: <b>{{element.Description}}</b>
                                                            <br />
                                                            Tỉnh thành: <b>{{element.DanhMucTinhThanhName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <!-- <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Họ tên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài khoản
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container> -->
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienService.displayColumns004">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienService.displayColumns004;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienChuyenGiaPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucNguyenVatLieu" role="tabpanel">
                                <div class="row gx-4">
                                    <!-- <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="DanhMucNguyenVatLieuPhanLoaiID"
                                            [(ngModel)]="DanhMucNguyenVatLieuPhanLoaiID">
                                            <option [value]="0">
                                                Tất cả (Cho phép + Cấm)</option>
                                            <option [value]="-1">
                                                Theo Cho phép + Cấm</option>
                                            <option *ngFor="let item of DanhMucNguyenVatLieuPhanLoaiService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChoPhep"
                                                [(ngModel)]="IsChoPhep" (change)="onIsChoPhepChange()" />
                                            <label class="form-check-label" for="IsChoPhep">Cho phép</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsCam"
                                                [(ngModel)]="IsCam" (change)="onIsCamChange()" />
                                            <label class="form-check-label" for="IsCam">Cấm</label>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucNguyenVatLieuService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucNguyenVatLieuSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="DanhMucNguyenVatLieuSearch()"
                                            style="width: 100%;"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="DanhMucNguyenVatLieuExportExcel()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Báo cáo"
                                            (click)="DanhMucNguyenVatLieuExportHTML()"><i
                                                class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vật tư
                                            <span
                                                *ngIf="DanhMucNguyenVatLieuService.list">({{DanhMucNguyenVatLieuService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucNguyenVatLieuSort="matSort"
                                                    [dataSource]="DanhMucNguyenVatLieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thông số kỹ thuật
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: left;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tên thương
                                                                        phẩm</label>
                                                                    {{element.Name}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hoạt chất</label>
                                                                    {{element.Code}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phòng trừ</label>
                                                                    {{element.Description}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đăng ký</label>
                                                                    {{element.ToChucName}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Pháp lý</label>
                                                                    {{element.Display}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Loại hình</label>
                                                                    {{element.ParentName}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Chức năng</label>
                                                                    {{element.FUNCTIONName}}
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsChoPhep{{element.ID}}"
                                                                            [(ngModel)]="element.IsChoPhep"
                                                                            (change)="onDanhMucNguyenVatLieuIsChoPhepChange(element)">
                                                                        <label class="form-check-label"
                                                                            for="IsChoPhep">Cho phép</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCam{{element.ID}}"
                                                                            [(ngModel)]="element.IsCam"
                                                                            (change)="onDanhMucNguyenVatLieuIsCamChange(element)">
                                                                        <label class="form-check-label"
                                                                            for="IsCam">Cấm</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucNguyenVatLieuService.displayColumns0002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucNguyenVatLieuService.displayColumns0002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucNguyenVatLieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucSanPhamGiong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucSanPhamGiongService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucSanPhamGiongSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="DanhMucSanPhamGiongSearch()"
                                            style="width: 100%;"><i class="bi bi-search"></i></a>
                                        <!-- <a class="btn btn-success" title="Thêm mới" (click)="DanhMucSanPhamGiongAdd(0)"><i
                                                class="bi bi-plus"></i></a> -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Giống
                                            <span
                                                *ngIf="DanhMucSanPhamGiongService.list">({{DanhMucSanPhamGiongService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucSanPhamGiongSort="matSort"
                                                    [dataSource]="DanhMucSanPhamGiongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thông tin chi tiết
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: left;">

                                                            Mã giống: <b>{{element.Code}}</b>
                                                            <br />
                                                            Số bằng bảo hộ: <b>{{element.TypeName}}</b>
                                                            <br />
                                                            Tên giống: <b>{{element.Name}}</b>
                                                            <br />
                                                            Loài cây: <b>{{element.Display}}</b>
                                                            <br />
                                                            Hiệu lực: <b>{{element.NgayHieuLuc |
                                                                date:'MM/dd/yyyy'}}</b>
                                                            <br />
                                                            Tình trạng: <b>{{element.Note}}</b>
                                                            <br />
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBaoHo{{element.ID}}"
                                                                            [(ngModel)]="element.IsBaoHo">
                                                                        <label class="form-check-label"
                                                                            for="IsBaoHo">Bảo
                                                                            hộ</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucSanPhamGiongService.displayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucSanPhamGiongService.displayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucSanPhamGiongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>