import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { MapComponent } from './map/map.component';
import { MapVungTrongComponent } from './map-vung-trong/map-vung-trong.component';
import { MapVungTrongInfoComponent } from './map-vung-trong-info/map-vung-trong-info.component';
import { ToChucInfoComponent } from './to-chuc-info/to-chuc-info.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HopTacXaComponent } from './hop-tac-xa/hop-tac-xa.component';
import { HopTacXaInfoComponent } from './hop-tac-xa-info/hop-tac-xa-info.component';
import { MapHopTacXaComponent } from './map-hop-tac-xa/map-hop-tac-xa.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { ThongKeComponent } from './thong-ke/thong-ke.component';
import { TuVanComponent } from './tu-van/tu-van.component';
import { HuongDanSuDungComponent } from './huong-dan-su-dung/huong-dan-su-dung.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';


const routes: Routes = [  
  { path: '', redirectTo: '/MapHopTacXa', pathMatch: 'full' },   
  {
    path: 'Homepage', component: MapComponent,
  }, 
  {
    path: 'Dashboard', component: DashboardComponent,
  }, 
  {
    path: 'Map', component: MapComponent,
  }, 
  {
    path: 'MapHopTacXa', component: MapHopTacXaComponent,
  }, 
  {
    path: 'MapVungTrong', component: MapVungTrongComponent,
  },  
  {
    path: 'HopTacXa', component: HopTacXaComponent,
  }, 
  {
    path: 'HopTacXaInfo/:ID', component: HopTacXaInfoComponent,
  },
  {
    path: 'VungTrongInfo/:ID', component: MapVungTrongInfoComponent,
  },
  {
    path: 'ToChucInfo/:ID', component: ToChucInfoComponent,
  },
  {
    path: 'GioiThieu', component: GioiThieuComponent,
  }, 
  {
    path: 'ThongKe', component: ThongKeComponent,
  },
  {
    path: 'TuVan', component: TuVanComponent,
  },
  {
    path: 'HuongDanSuDung', component: HuongDanSuDungComponent,
  },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
