import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienLichSuTruyCapService extends BaseService{

    list: ThanhVienLichSuTruyCap[] | undefined;
    list001: ThanhVienLichSuTruyCap[] | undefined;
    formData!: ThanhVienLichSuTruyCap;    

    displayColumns: string[] = ['Note','CreatedDate', 'Code'];    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienLichSuTruyCap";
    }
}

