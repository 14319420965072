<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-bank2 fs-3 text-primary"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>{{ToChucService.formData.Name}}</b></h2>
    </div>
</div>
<div class="app-body">
    <div class="row">
        <div class="alert alert-primary rounded-3" role="alert" style="width: 100%;">
            <h6><b>Thông tin Hợp tác xã</b></h6>
            <hr>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-4 col-4">
                    Mã số thuế:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.Code}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Điện thoại:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.DienThoai}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Email:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.Email}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Diện tích (ha):
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.DienTichSanXuatHa}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    HTX:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.SoLuongThanhVien}}</b> (Thành viên)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Địa chỉ:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.DiaChi}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Ấp thôn:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.ApThon}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Tỉnh thành:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.DanhMucTinhThanhName}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Quận huyện:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.DanhMucQuanHuyenName}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Xã phường:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{ToChucService.formData.DanhMucXaPhuongName}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Trụ sở:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <input type="checkbox" class="form-check-input" name="IsTruSo"
                        [(ngModel)]="ToChucService.formData.IsTruSo" />
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Tổ dịch vụ:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <input type="checkbox" class="form-check-input" name="IsToDichVu"
                        [(ngModel)]="ToChucService.formData.IsToDichVu" />
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Kích hoạt:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <input type="checkbox" class="form-check-input" name="Active"
                        [(ngModel)]="ToChucService.formData.Active" />
                </div>
            </div>
        </div>
        <div class="alert alert-success rounded-3" role="alert" style="width: 100%;">
            <h6><b>Vị trí Hợp tác xã và Vùng trồng</b></h6>
            <div class="map" #map style="height: 600px; width: 100%;">
                <div style="z-index: 1000; position: absolute; top: 0;">
                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                </div>
                <div
                    style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                </div>
            </div>
        </div>
        <div class="alert alert-info rounded-3" role="alert" style="width: 100%;">
            <h6><b>Năng lực sản xuất</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucQuyTrinhSanXuatService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Đề án:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Sản phẩm:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Code}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Diện tích:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.DienTich}}</b> (ha)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Sản lượng:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.SanLuong}}</b> (tấn)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Số vụ:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.SoVu}}</b>
                </div>
            </div>
        </div>
        <div class="alert alert-warning rounded-3" role="alert" style="width: 100%;">
            <h6><b>Đề án tham gia</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucDuAnService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Đề án:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Diện tích:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.DienTich}}</b> (ha)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Sản lượng:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.SanLuong}}</b> (tấn)
                </div>
            </div>
        </div>
        <div class="alert alert-danger rounded-3" role="alert" style="width: 100%;">
            <h6><b>Thành viên</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucThanhVienService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Họ tên:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Điện thoại:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Code}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Kinh nghiệm:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.NamKinhNghiem}}</b> (năm)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Chức danh:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Display}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Chứng chỉ:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.TypeName}}</b>
                </div>
            </div>
        </div>
        <div class="alert alert-primary rounded-3" role="alert" style="width: 100%;">
            <h6><b>Sản phẩm | Giống | Dịch vụ</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucSanPhamService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Tiêu đề:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
            </div>
        </div>
        <div class="alert alert-success rounded-3" role="alert" style="width: 100%;">
            <h6><b>Tiêu chuẩn sản xuất</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucTieuChuanSanXuatService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Tiêu chuẩn:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Diện tích:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.DienTich}}</b> (ha)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Sản lượng:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.SanLuong}}</b> (tấn)
                </div>
            </div>
        </div>
        <div class="alert alert-info rounded-3" role="alert" style="width: 100%;">
            <h6><b>Thiết bị</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucKhoThietBiService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Thiết bị:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Số lượng:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.SoLuong}}</b> (Cái | M2)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Công suất:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.CongSuat}}</b> (KW)
                </div>
            </div>
        </div>
        <div class="alert alert-warning rounded-3" role="alert" style="width: 100%;">
            <h6><b>Liên kết - Hợp tác</b></h6>
            <div class="row gx-4" *ngFor="let element of ToChucHopTacService.list; index as i">
                <hr />
                <div class="col-lg-4 col-sm-4 col-4">
                    Đối tác:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Name}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Hình thức:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.Code}}</b>
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Diện tích:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.DienTich}}</b> (ha)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Sản lượng:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.SanLuong}}</b> (tấn)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Thời gian:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.ThoiGianLienKet}}</b> (năm)
                </div>
                <div class="col-lg-4 col-sm-4 col-4">
                    Năm bắt đầu:
                </div>
                <div class="col-lg-8 col-sm-8 col-8">
                    <b>{{element.NamBatDau}}</b>
                </div>
            </div>
        </div>
        <div class="alert alert-danger rounded-3" role="alert" style="width: 100%;">
            <h6><b>Quy trình sản xuất (Mở ra để xem chi tiết) <i (click)="ShowToChucQuyTrinhSanXuatChiTiet()"
                        style="float: right;" class="bi bi-arrows-expand"></i></b></h6>
            <div *ngIf="IsShowToChucQuyTrinhSanXuatChiTiet">
                <div class="row gx-4" *ngFor="let element of ToChucQuyTrinhSanXuatChiTietService.list; index as i">
                    <hr />
                    <div class="col-lg-4 col-sm-4 col-4">
                        Quy trình:
                    </div>
                    <div class="col-lg-8 col-sm-8 col-8">
                        <b>{{element.Name}}</b>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-4">
                        Phân đoạn:
                    </div>
                    <div class="col-lg-8 col-sm-8 col-8">
                        <b>{{element.Code}}</b>
                    </div>
                    <div class="col-lg-4 col-sm-4 col-4">
                        Chỉ số KH:
                    </div>
                    <div class="col-lg-8 col-sm-8 col-8">
                        <b>{{element.SoLuong}}</b>
                        <!-- (Tháng | %) -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>