<div class="d-flex align-items-center"
    style="border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid; padding: 5px;">
    <div class="me-3 icon-box md bg-white rounded-4">
        <i class="bi bi-search fs-3 text-primary" (click)="SetIsShowSearch()"></i>
    </div>
    <div>
        <h2 class="mb-1" style="font-size: 16px;"><b>Hợp tác xã tham gia đề án giai đoạn 2023-2025</b></h2>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4" *ngIf="isShowSearch">
        <div class="col-lg-3 col-sm-12 col-12">
            <label class="form-label">Tỉnh thành</label>
            <mat-select class="form-control" name="DanhMucTinhThanhID" (selectionChange)="DanhMucQuanHuyenGetToList()"
                [(ngModel)]="DanhMucTinhThanhID">
                <input placeholder="Tìm..." class="form-control" (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <label class="form-label">Quận huyện</label>
            <mat-select class="form-control" name="DanhMucQuanHuyenID" (selectionChange)="DanhMucXaPhuongGetToList()"
                [(ngModel)]="DanhMucQuanHuyenID">
                <input placeholder="Tìm..." class="form-control" (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                <mat-option [value]="0">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <label class="form-label">Xã phường</label>
            <mat-select class="form-control" name="DanhMucXaPhuongID" [(ngModel)]="DanhMucXaPhuongID"
                (selectionChange)="ToChucSearch()">
                <input placeholder="Tìm..." class="form-control" (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                <mat-option [value]="0">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-lg-4 col-sm-12 col-12">
            <label class="form-label">.</label>
            <input [(ngModel)]="ToChucService.SearchString" placeholder="Tìm ..." class="form-control"
                (keyup.enter)="ToChucSearch()">
        </div>
        <div class="col-lg-1 col-sm-12 col-12">
            <label class="form-label">.</label>
            <div>
                <a class="btn btn-success" title="Tìm" (click)="ToChucSearch()" style="width: 100%;"><i
                        class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
    </div>
    <br />   
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12" *ngFor="let element of ToChucService.list000; index as i"
            style="margin: 0px;">
            <div [ngClass]="{'alert alert-primary rounded-3': i % 5==0}" role="alert" style="width: 100%;">
                <div [ngClass]="{'alert alert-success rounded-3': i % 5==1}" role="alert" style="width: 100%;">
                    <div [ngClass]="{'alert alert-info rounded-3': i % 5==2}" role="alert" style="width: 100%;">
                        <div [ngClass]="{'alert alert-warning rounded-3': i % 5==3}" role="alert" style="width: 100%;">
                            <div [ngClass]="{'alert alert-danger rounded-3': i % 5==4}" role="alert"
                                style="width: 100%;">
                                <h6><b>{{element.Name}}</b></h6>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Điện thoại:
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.DienThoai}}</b>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Email:
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.Email}}</b>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Diện tích (ha):
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.DienTichSanXuatHa}}</b>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Thành viên:
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.SoLuongThanhVien}}</b>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Tỉnh thành:
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.DanhMucTinhThanhName}}</b>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Quận huyện:
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.DanhMucQuanHuyenName}}</b>
                                    </div>
                                    <div class="col-lg-4 col-sm-4 col-4">
                                        Xã phường:
                                    </div>
                                    <div class="col-lg-8 col-sm-8 col-8">
                                        <b>{{element.DanhMucXaPhuongName}}</b>
                                    </div>
                                </div>
                                <br />                                
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-6 col-6">
                                        <a href="{{domainName}}HopTacXaInfo/{{element.ID}}" class="btn btn-success"
                                            title="Xem chi tiết" style="width: 100%;"><i
                                                class="bi bi-file-earmark-font"></i>&nbsp;&nbsp;&nbsp;Xem chi tiết</a>
                                    </div>
                                    <div class="col-lg-6 col-sm-6 col-6">
                                        <a *ngIf="ThanhVienService.formDataLogin.ParentID==5 || ThanhVienService.formDataLogin.ParentID==3" (click)="ToChucExportHopTacXaByIDToHTMLAsync(element)"
                                            class="btn btn-success" title="Hồ sơ (PDF)" style="width: 100%;"><i
                                                class="bi bi-printer"></i>&nbsp;&nbsp;&nbsp;Hồ sơ (PDF)</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>