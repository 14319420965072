import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import * as maplibregl from 'maplibre-gl';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';
import { ToChucKhoThietBi } from 'src/app/shared/ToChucKhoThietBi.model';
import { ToChucKhoThietBiService } from 'src/app/shared/ToChucKhoThietBi.service';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { ToChucHopTacService } from 'src/app/shared/ToChucHopTac.service';
import { ToChucTieuChuanSanXuat } from 'src/app/shared/ToChucTieuChuanSanXuat.model';
import { ToChucTieuChuanSanXuatService } from 'src/app/shared/ToChucTieuChuanSanXuat.service';
import { ToChucSanPham } from 'src/app/shared/ToChucSanPham.model';
import { ToChucSanPhamService } from 'src/app/shared/ToChucSanPham.service';
import { ToChucDuAn } from 'src/app/shared/ToChucDuAn.model';
import { ToChucDuAnService } from 'src/app/shared/ToChucDuAn.service';
import { ToChucQuyTrinhSanXuat } from 'src/app/shared/ToChucQuyTrinhSanXuat.model';
import { ToChucQuyTrinhSanXuatService } from 'src/app/shared/ToChucQuyTrinhSanXuat.service';
import { ToChucQuyTrinhSanXuatChiTiet } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.model';
import { ToChucQuyTrinhSanXuatChiTietService } from 'src/app/shared/ToChucQuyTrinhSanXuatChiTiet.service';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';


import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucThietBi } from 'src/app/shared/DanhMucThietBi.model';
import { DanhMucThietBiService } from 'src/app/shared/DanhMucThietBi.service';
import { DanhMucLienKet } from 'src/app/shared/DanhMucLienKet.model';
import { DanhMucLienKetService } from 'src/app/shared/DanhMucLienKet.service';
import { DanhMucTieuChuanSanXuat } from 'src/app/shared/DanhMucTieuChuanSanXuat.model';
import { DanhMucTieuChuanSanXuatService } from 'src/app/shared/DanhMucTieuChuanSanXuat.service';
import { DanhMucSanPham } from 'src/app/shared/DanhMucSanPham.model';
import { DanhMucSanPhamService } from 'src/app/shared/DanhMucSanPham.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';
import { DanhMucQuyTrinhSanXuat } from 'src/app/shared/DanhMucQuyTrinhSanXuat.model';
import { DanhMucQuyTrinhSanXuatService } from 'src/app/shared/DanhMucQuyTrinhSanXuat.service';
import { DanhMucPhanDoanSanXuat } from 'src/app/shared/DanhMucPhanDoanSanXuat.model';
import { DanhMucPhanDoanSanXuatService } from 'src/app/shared/DanhMucPhanDoanSanXuat.service';


@Component({
  selector: 'app-hop-tac-xa-info',
  templateUrl: './hop-tac-xa-info.component.html',
  styleUrls: ['./hop-tac-xa-info.component.css']
})
export class HopTacXaInfoComponent implements OnInit {

  isShowLoading: boolean = false;
  IsShowToChucQuyTrinhSanXuatChiTiet: boolean = false;
  queryString: string = environment.InitializationString;
  searchString: string = environment.InitializationString;
  domainURL = environment.DomainURL;
  
  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  constructor(
    public router: Router,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucService: ToChucService,
    public ToChucThanhVienService: ToChucThanhVienService,
    public ToChucKhoThietBiService: ToChucKhoThietBiService,
    public ToChucHopTacService: ToChucHopTacService,
    public ToChucTieuChuanSanXuatService: ToChucTieuChuanSanXuatService,
    public ToChucSanPhamService: ToChucSanPhamService,
    public ToChucDuAnService: ToChucDuAnService,
    public ToChucQuyTrinhSanXuatService: ToChucQuyTrinhSanXuatService,
    public ToChucQuyTrinhSanXuatChiTietService: ToChucQuyTrinhSanXuatChiTietService,
    public ToChucToaDoService: ToChucToaDoService,


    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DanhMucChungChiService: DanhMucChungChiService,
    public DanhMucThietBiService: DanhMucThietBiService,
    public DanhMucLienKetService: DanhMucLienKetService,
    public DanhMucTieuChuanSanXuatService: DanhMucTieuChuanSanXuatService,
    public DanhMucSanPhamService: DanhMucSanPhamService,
    public DanhMucDuAnService: DanhMucDuAnService,
    public DanhMucQuyTrinhSanXuatService: DanhMucQuyTrinhSanXuatService,
    public DanhMucPhanDoanSanXuatService: DanhMucPhanDoanSanXuatService,
  ) {
    this.DanhMucChucDanhGetToList();
    this.DanhMucChungChiGetToList();
    this.DanhMucThietBiGetToList();
    this.DanhMucLienKetGetToList();
    this.DanhMucTieuChuanSanXuatGetToList();
    this.DanhMucDuAnGetToList();
    this.DanhMucSanPhamGetToList();
    this.DanhMucQuyTrinhSanXuatGetToList();
    this.DanhMucPhanDoanSanXuatGetToList();
    this.ToChucGetToList();
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.ToChucService.formData.ID = parseInt(this.queryString.split("/")[2]);
        if (this.queryString) {
          if (this.queryString.length > 0) {
            this.GetByQueryString();
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

  GetByQueryString() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.IDString = this.queryString
    this.ToChucService.GetByIDStringAsync().subscribe(res => {
      this.ToChucService.formData = res as ToChuc;
      if (this.ToChucService.formData) {
        if (this.ToChucService.formData.ID > 0) {
          this.ToChucThanhVienGetToList();
          this.ToChucKhoThietBiGetToList();
          this.ToChucTieuChuanSanXuatGetToList();
          this.ToChucSanPhamGetToList();
          this.ToChucDuAnGetToList();
          this.ToChucQuyTrinhSanXuatGetToList();
          this.MapLoad();
        }
      }
      this.isShowLoading = false;
    });
  }

  ToChucThanhVienGetToList() {
    this.isShowLoading = true;
    this.ToChucThanhVienService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucThanhVienService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucThanhVienService.list = (res as ToChucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucThanhVienService.list = this.ToChucThanhVienService.list.filter(item => item.Name && item.Name.length > 0);
        for (let i = 0; i < this.ToChucThanhVienService.list.length; i++) {
          for (let j = 0; j < this.DanhMucChucDanhService.list.length; j++) {
            if (this.ToChucThanhVienService.list[i].DanhMucChucDanhID == this.DanhMucChucDanhService.list[j].ID) {
              this.ToChucThanhVienService.list[i].Display = this.DanhMucChucDanhService.list[j].Name;
              j = this.DanhMucChucDanhService.list.length;
            }
          }
          for (let j = 0; j < this.DanhMucChungChiService.list.length; j++) {
            if (this.ToChucThanhVienService.list[i].DanhMucChungChiID == this.DanhMucChungChiService.list[j].ID) {
              this.ToChucThanhVienService.list[i].TypeName = this.DanhMucChungChiService.list[j].Name;
              j = this.DanhMucChungChiService.list.length;
            }
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucKhoThietBiGetToList() {
    this.isShowLoading = true;
    this.ToChucKhoThietBiService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucKhoThietBiService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucKhoThietBiService.list = (res as ToChucKhoThietBi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        for (let i = 0; i < this.ToChucKhoThietBiService.list.length; i++) {
          for (let j = 0; j < this.DanhMucThietBiService.list.length; j++) {
            if (this.ToChucKhoThietBiService.list[i].DanhMucThietBiID == this.DanhMucThietBiService.list[j].ID) {
              this.ToChucKhoThietBiService.list[i].Name = this.DanhMucThietBiService.list[j].Name;
              j = this.DanhMucThietBiService.list.length;
            }
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucHopTacGetToList() {
    this.isShowLoading = true;
    this.ToChucHopTacService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucHopTacService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucHopTacService.list = (res as ToChucHopTac[]).sort((a, b) => (a.ToChucID > b.ToChucID ? 1 : -1));
        for (let i = 0; i < this.ToChucHopTacService.list.length; i++) {
          for (let j = 0; j < this.ToChucService.list.length; j++) {
            if (this.ToChucHopTacService.list[i].ToChucID == this.ToChucService.list[j].ID) {
              this.ToChucHopTacService.list[i].Name = this.ToChucService.list[j].Name;
              j = this.ToChucService.list.length;
            }
          }

          for (let j = 0; j < this.DanhMucLienKetService.list.length; j++) {
            if (this.ToChucHopTacService.list[i].DanhMucLienKetID == this.DanhMucLienKetService.list[j].ID) {
              this.ToChucHopTacService.list[i].Code = this.DanhMucLienKetService.list[j].Name;
              j = this.DanhMucLienKetService.list.length;
            }
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucTieuChuanSanXuatGetToList() {
    this.isShowLoading = true;
    this.ToChucTieuChuanSanXuatService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucTieuChuanSanXuatService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucTieuChuanSanXuatService.list = (res as ToChucTieuChuanSanXuat[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        for (let i = 0; i < this.ToChucTieuChuanSanXuatService.list.length; i++) {
          for (let j = 0; j < this.DanhMucTieuChuanSanXuatService.list.length; j++) {
            if (this.ToChucTieuChuanSanXuatService.list[i].DanhMucTieuChuanSanXuatID == this.DanhMucTieuChuanSanXuatService.list[j].ID) {
              this.ToChucTieuChuanSanXuatService.list[i].Name = this.DanhMucTieuChuanSanXuatService.list[j].Name;
              j = this.DanhMucTieuChuanSanXuatService.list.length;
            }
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucSanPhamGetToList() {
    this.isShowLoading = true;
    this.ToChucSanPhamService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucSanPhamService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucSanPhamService.list = (res as ToChucSanPham[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        for (let i = 0; i < this.ToChucSanPhamService.list.length; i++) {
          for (let j = 0; j < this.DanhMucSanPhamService.list.length; j++) {
            if (this.ToChucSanPhamService.list[i].DanhMucSanPhamID == this.DanhMucSanPhamService.list[j].ID) {
              this.ToChucSanPhamService.list[i].Name = this.DanhMucSanPhamService.list[j].Name;
              j = this.DanhMucSanPhamService.list.length;
            }
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucDuAnGetToList() {
    this.isShowLoading = true;
    this.ToChucDuAnService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucDuAnService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucDuAnService.list = (res as ToChucDuAn[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        for (let i = 0; i < this.ToChucDuAnService.list.length; i++) {
          for (let j = 0; j < this.DanhMucDuAnService.list.length; j++) {
            if (this.ToChucDuAnService.list[i].DanhMucDuAnID == this.DanhMucDuAnService.list[j].ID) {
              this.ToChucDuAnService.list[i].Name = this.DanhMucDuAnService.list[j].Name;
              j = this.DanhMucDuAnService.list.length;
            }
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucQuyTrinhSanXuatGetToList() {
    this.isShowLoading = true;
    this.ToChucQuyTrinhSanXuatService.baseParameter.ParentID = this.ToChucService.formData.ID;
    this.ToChucQuyTrinhSanXuatService.GetByParentIDToListAsync().subscribe(
      res => {
        this.ToChucQuyTrinhSanXuatService.list = (res as ToChucQuyTrinhSanXuat[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        for (let i = 0; i < this.ToChucQuyTrinhSanXuatService.list.length; i++) {
          for (let j = 0; j < this.DanhMucDuAnService.list.length; j++) {
            if (this.ToChucQuyTrinhSanXuatService.list[i].DanhMucDuAnID == this.DanhMucDuAnService.list[j].ID) {
              this.ToChucQuyTrinhSanXuatService.list[i].Name = this.DanhMucDuAnService.list[j].Name;
              j = this.DanhMucDuAnService.list.length;
            }
          }
          for (let j = 0; j < this.DanhMucSanPhamService.list.length; j++) {
            if (this.ToChucQuyTrinhSanXuatService.list[i].DanhMucSanPhamID == this.DanhMucSanPhamService.list[j].ID) {
              this.ToChucQuyTrinhSanXuatService.list[i].Code = this.DanhMucSanPhamService.list[j].Name;
              j = this.DanhMucSanPhamService.list.length;
            }
          }
        }

        if (this.ToChucQuyTrinhSanXuatService.list) {
          if (this.ToChucQuyTrinhSanXuatService.list.length > 0) {

            this.isShowLoading = true;
            this.ToChucQuyTrinhSanXuatChiTietService.baseParameter.ParentID = this.ToChucQuyTrinhSanXuatService.list[0].ID;
            this.ToChucQuyTrinhSanXuatChiTietService.GetByParentIDToListAsync().subscribe(
              res => {
                this.ToChucQuyTrinhSanXuatChiTietService.list = (res as ToChucQuyTrinhSanXuatChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
                for (let i = 0; i < this.ToChucQuyTrinhSanXuatChiTietService.list.length; i++) {

                  for (let j = 0; j < this.DanhMucQuyTrinhSanXuatService.list.length; j++) {
                    if (this.ToChucQuyTrinhSanXuatChiTietService.list[i].DanhMucQuyTrinhSanXuatID == this.DanhMucQuyTrinhSanXuatService.list[j].ID) {
                      this.ToChucQuyTrinhSanXuatChiTietService.list[i].Name = this.DanhMucQuyTrinhSanXuatService.list[j].Name;
                      j = this.DanhMucQuyTrinhSanXuatService.list.length;
                    }
                  }

                  for (let j = 0; j < this.DanhMucPhanDoanSanXuatService.list.length; j++) {
                    if (this.ToChucQuyTrinhSanXuatChiTietService.list[i].DanhMucPhanDoanSanXuatID == this.DanhMucPhanDoanSanXuatService.list[j].ID) {
                      this.ToChucQuyTrinhSanXuatChiTietService.list[i].Code = this.DanhMucPhanDoanSanXuatService.list[j].Name;
                      j = this.DanhMucPhanDoanSanXuatService.list.length;
                    }
                  }
                }
                this.isShowLoading = false;
              },
              err => {
                this.isShowLoading = false;
              }
            );

          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucGetToList() {
    this.isShowLoading = true;
    this.ToChucService.GetAllToListAsync().subscribe(
      res => {
        this.ToChucService.list = (res as ToChuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucHopTacGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucQuyTrinhSanXuatGetToList() {
    this.DanhMucQuyTrinhSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucPhanDoanSanXuatGetToList() {
    this.DanhMucPhanDoanSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucDuAnGetToList() {
    this.DanhMucDuAnService.ComponentGetAllToListAsync();
  }

  DanhMucSanPhamGetToList() {
    this.DanhMucSanPhamService.ComponentGetAllToListAsync();
  }

  DanhMucTieuChuanSanXuatGetToList() {
    this.DanhMucTieuChuanSanXuatService.ComponentGetAllToListAsync();
  }

  DanhMucLienKetGetToList() {
    this.DanhMucLienKetService.ComponentGetAllToListAsync();
  }

  DanhMucChucDanhGetToList() {
    this.DanhMucChucDanhService.ComponentGetAllToListAsync();
  }

  DanhMucChungChiGetToList() {
    this.DanhMucChungChiService.ComponentGetAllToListAsync();
  }

  DanhMucThietBiGetToList() {
    this.DanhMucThietBiService.ComponentGetAllToListAsync();
  }

  ShowToChucQuyTrinhSanXuatChiTiet() {
    this.IsShowToChucQuyTrinhSanXuatChiTiet = !this.IsShowToChucQuyTrinhSanXuatChiTiet;
  }

  MapInitialization(longitude, latitude) {

    let zoom = 12;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });
    this.map.addControl(new maplibregl.FullscreenControl());
    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );

    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {

    if (this.ToChucService.formData) {
      this.ToChucService.baseParameter.ID = this.ToChucService.formData.ID;
      this.ToChucService.GetSQLByToChucToaDoByIDToListAsync().subscribe(res => {
        this.ToChucService.list1003 = res as ToChuc[];
        if (this.ToChucService.list1003) {
          if (this.ToChucService.list1003.length > 0) {
            let listToChucToaDoActive = [];
            let listToChucToaDoActiveNot = [];

            for (let i = 0; i < this.ToChucService.list1003.length; i++) {
              if (this.ToChucService.list1003[i].Active == true) {
                listToChucToaDoActive.push(this.ToChucService.list1003[i]);
              }
            }
            if (listToChucToaDoActive) {
              if (listToChucToaDoActive.length > 0) {
                let toChuc = listToChucToaDoActive[0];
                let latitude = Number(toChuc.ViDo);
                let longitude = Number(toChuc.KinhDo);

                this.MapInitialization(longitude, latitude);

                if (latitude <= 90) {

                  let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                  popupContent = popupContent + "<a class='link-primary form-label' href='" + environment.DomainDestination + "HopTacXaInfo/" + toChuc.ID + "'><h1>" + toChuc.Name + "</h1></a>";
                  popupContent = popupContent + '<div><b>' + toChuc.DanhMucXaPhuongName + ', ' + toChuc.DanhMucQuanHuyenName + ', ' + toChuc.DanhMucTinhThanhName + '</b></div>';
                  popupContent = popupContent + "</div>";

                  let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                    .setMaxWidth("600px");

                  var el = document.createElement('div');
                  el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/HTX4_30.png)";
                  el.style.width = '30px';
                  el.style.height = '30px';

                  let marker = new maplibregl.Marker({ element: el })
                    .setLngLat([longitude, latitude])
                    .setPopup(popup)
                    .addTo(this.map);
                }
              }
            }

            let listToChucToaDoPolygon = [];
            let listPolygon = [];

            for (let i = 0; i < this.ToChucService.list1003.length; i++) {
              if (this.ToChucService.list1003[i].Active == false) {
                let latitudeSub1 = Number(this.ToChucService.list1003[i].ViDo);
                let longitudeSub1 = Number(this.ToChucService.list1003[i].KinhDo);
                listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
              }
            }

            if (listToChucToaDoPolygon.length > 0) {

              listPolygon.push(listToChucToaDoPolygon);

              let layerID = 'Layer_';
              let sourceID = 'Source_';

              this.map.on('load', () => {

                this.map.addSource(sourceID, {
                  'type': 'geojson',
                  'data': {
                    'type': 'Feature',
                    'properties': {
                      "name": "",
                      "address": "",
                    },
                    'geometry': {
                      'type': 'Polygon',
                      'coordinates': listPolygon,
                    }
                  }
                });

                let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);

                this.map.addLayer({
                  'id': layerID,
                  'type': 'fill',
                  'source': sourceID,
                  'paint': {
                    'fill-color': color,
                    'fill-opacity': 0.5,
                    'fill-outline-color': color,
                  }
                });
                
              });
            }
          }
        }
      });
    }
  }
}
