import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DanhMucChungChi } from 'src/app/shared/DanhMucChungChi.model';
import { DanhMucChungChiService } from 'src/app/shared/DanhMucChungChi.service';
import { DanhMucLienKet } from 'src/app/shared/DanhMucLienKet.model';
import { DanhMucLienKetService } from 'src/app/shared/DanhMucLienKet.service';
import { DanhMucSanPham } from 'src/app/shared/DanhMucSanPham.model';
import { DanhMucSanPhamService } from 'src/app/shared/DanhMucSanPham.service';
import { DanhMucTieuChuanSanXuat } from 'src/app/shared/DanhMucTieuChuanSanXuat.model';
import { DanhMucTieuChuanSanXuatService } from 'src/app/shared/DanhMucTieuChuanSanXuat.service';
import { DanhMucThietBi } from 'src/app/shared/DanhMucThietBi.model';
import { DanhMucThietBiService } from 'src/app/shared/DanhMucThietBi.service';
import { DanhMucDuAn } from 'src/app/shared/DanhMucDuAn.model';
import { DanhMucDuAnService } from 'src/app/shared/DanhMucDuAn.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucThanhVien } from 'src/app/shared/ToChucThanhVien.model';
import { ToChucThanhVienService } from 'src/app/shared/ToChucThanhVien.service';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { ToChucHopTacService } from 'src/app/shared/ToChucHopTac.service';
import { ToChucTieuChuanSanXuat } from 'src/app/shared/ToChucTieuChuanSanXuat.model';
import { ToChucTieuChuanSanXuatService } from 'src/app/shared/ToChucTieuChuanSanXuat.service';
import { ToChucSanPham } from 'src/app/shared/ToChucSanPham.model';
import { ToChucSanPhamService } from 'src/app/shared/ToChucSanPham.service';
import { ToChucKhoThietBi } from 'src/app/shared/ToChucKhoThietBi.model';
import { ToChucKhoThietBiService } from 'src/app/shared/ToChucKhoThietBi.service';
import { ToChucDuAn } from 'src/app/shared/ToChucDuAn.model';
import { ToChucDuAnService } from 'src/app/shared/ToChucDuAn.service';
import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';


@Component({
  selector: 'app-hop-tac-xa',
  templateUrl: './hop-tac-xa.component.html',
  styleUrls: ['./hop-tac-xa.component.css']
})
export class HopTacXaComponent implements OnInit {



  domainName = environment.DomainDestination;
  isShowLoading: boolean = false;
  isShowSearch: boolean = false;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  ToChucID: number = environment.InitializationNumber;



  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,


    public ToChucService: ToChucService,


    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
  }

  ToChucExportHopTacXaByIDToHTMLAsync(element: ToChuc) {
    this.isShowLoading = true;
    this.DownloadService.baseParameter.ID = element.ID;
    this.DownloadService.ExportHopTacXaByIDToHTMLAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );

    //this.NotificationService.warn("Liên hệ quản trị hệ thống");
  }

  SetIsShowSearch() {
    this.isShowSearch = !this.isShowSearch;
  }
  ToChucSearch() {
    this.ToChucGetToList();
  }
  ToChucGetToList() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ToChucService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ToChucService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ToChucService.baseParameter.SearchString = this.ToChucService.SearchString;
    this.ToChucService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.list000 = (res as ToChuc[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (this.ToChucService.list000) {
          if (this.ToChucService.list000.length > 0) {
            this.ToChucID = this.ToChucService.list000[0].ID;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetSQLByThanhVienIDAndActiveToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length > 0) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetSQLByThanhVienIDAndActiveAndParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
    this.ToChucSearch();
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }
}
