<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{DanhMucSanPhamGiongService.formData.Name}} [{{DanhMucSanPhamGiongService.formData.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Giống
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-DanhMucSanPhamGiongDetail" data-bs-toggle="tab"
                                    href="#DanhMucSanPhamGiongDetail" role="tab" aria-controls="DanhMucSanPhamGiongDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Giống</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucSanPhamGiongThanhVien" data-bs-toggle="tab"
                                    href="#DanhMucSanPhamGiongThanhVien" role="tab" aria-controls="DanhMucSanPhamGiongThanhVien"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">02: Phân phối</span>
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="DanhMucSanPhamGiongDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="DanhMucSanPhamGiongSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Thêm mới" (click)="DanhMucSanPhamGiongNew()"
                                            class="btn btn-success"><i class="bi bi-plus"></i> Thêm mới</a>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="DanhMucSanPhamGiongService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsBaoHo"
                                                [(ngModel)]="DanhMucSanPhamGiongService.formData.IsBaoHo" />
                                            <label class="form-check-label" for="IsBaoHo">Bảo hộ</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tên giống</label>
                                            <input name="Name" [(ngModel)]="DanhMucSanPhamGiongService.formData.Name"
                                                placeholder="Tên giống" type="text" class="form-control">
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã giống</label>
                                            <input name="Code" [(ngModel)]="DanhMucSanPhamGiongService.formData.Code"
                                                placeholder="Mã giống" type="text" class="form-control">
                                        </div>    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số bằng bảo hộ</label>
                                            <input name="TypeName" [(ngModel)]="DanhMucSanPhamGiongService.formData.TypeName"
                                                placeholder="Số bằng bảo hộ" type="text" class="form-control">
                                        </div>    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại cây</label>
                                            <input name="LoaiCay" [(ngModel)]="DanhMucSanPhamGiongService.formData.LoaiCay"
                                                placeholder="Loại cây" type="text" class="form-control">
                                        </div> 
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loài cây</label>
                                            <input name="Display" [(ngModel)]="DanhMucSanPhamGiongService.formData.Display"
                                                placeholder="Loài cây" type="text" class="form-control">
                                        </div> 
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tình trạng</label>
                                            <input name="Note" [(ngModel)]="DanhMucSanPhamGiongService.formData.Note"
                                                placeholder="Tình trạng" type="text" class="form-control">
                                        </div> 
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đăng ký</label>
                                            <input name="ToChucName" [(ngModel)]="DanhMucSanPhamGiongService.formData.ToChucName"
                                                placeholder="Đăng ký" type="text" class="form-control">
                                        </div>   
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">                                               
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình thức công bố lưu hành</label>
                                            <input name="HinhThucCongBoLuuHanh" [(ngModel)]="DanhMucSanPhamGiongService.formData.HinhThucCongBoLuuHanh"
                                                placeholder="Hình thức công bố lưu hành" type="text" class="form-control">
                                        </div>       
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số quyết định</label>
                                            <input name="SoQuyetDinh" [(ngModel)]="DanhMucSanPhamGiongService.formData.SoQuyetDinh"
                                                placeholder="Số quyết định" type="text" class="form-control">
                                        </div>       
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phạm vi lưu hành</label>
                                            <input name="PhamViLuuHanh" [(ngModel)]="DanhMucSanPhamGiongService.formData.PhamViLuuHanh"
                                                placeholder="Phạm vi lưu hành" type="text" class="form-control">
                                        </div>        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <input name="TinhThanh" [(ngModel)]="DanhMucSanPhamGiongService.formData.TinhThanh"
                                                placeholder="Tỉnh thành" type="text" class="form-control">
                                        </div>  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Thời gian lưu hành</label>
                                            <input name="ThoiGianLuuHanh" [(ngModel)]="DanhMucSanPhamGiongService.formData.ThoiGianLuuHanh"
                                                placeholder="Thời gian lưu hành" type="text" class="form-control">
                                        </div>  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quyết định (Tháng/Ngày/Năm)</label>
                                            <input name="NgayQuyetDinh" class="form-control" style="text-align: right;"
                                                [ngModel]="DanhMucSanPhamGiongService.formData.NgayQuyetDinh | date:'yyyy-MM-dd'"
                                                (ngModelChange)="NgayQuyetDinhChange($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hiệu lực (Tháng/Ngày/Năm)</label>
                                            <input name="NgayHieuLuc" class="form-control" style="text-align: right;"
                                                [ngModel]="DanhMucSanPhamGiongService.formData.NgayHieuLuc | date:'yyyy-MM-dd'"
                                                (ngModelChange)="NgayHieuLucChange($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                        </div>      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>