<nav id="sidebar" class="sidebar-wrapper">
      <div class="app-brand p-3 mb-3">
            <a href="#" *ngIf="ThanhVienService.formDataLogin && ThanhVienService.formDataLogin.Name">
                 {{ThanhVienService.formDataLogin.Name}}</a>
      </div>
      <div class="sidebarMenuScroll">
            <ul class="sidebar-menu">
                  <li>
                        <a href="{{domainName}}Map" title="Bản đồ Tổng hợp">
                              <i class="bi bi-globe"></i>
                              <span class="menu-text">1.1 Bản đồ Tổng hợp</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}MapHopTacXa" title="Bản đồ Hợp tác xã">
                              <i class="bi bi-globe"></i>
                              <span class="menu-text">1.2 Bản đồ Hợp tác xã</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}MapVungTrong" title="Bản đồ Vùng trồng">
                              <i class="bi bi-globe"></i>
                              <span class="menu-text">1.3 Bản đồ Vùng trồng</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainURL}}html/MapThoiTiet.html" title="Bản đồ Thời tiết">
                              <i class="bi bi-globe"></i>
                              <span class="menu-text">1.4 Bản đồ Thời tiết</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}Dashboard" title="Dashboard">
                              <i class="bi bi-bar-chart-fill"></i>
                              <span class="menu-text">2.1 Dashboard</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}ThongKe" title="Thống kê">
                              <i class="bi bi-bar-chart"></i>
                              <span class="menu-text">2.2 Thống kê</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}HopTacXa" title="Hợp tác xã">
                              <i class="bi bi-bank2"></i>
                              <span class="menu-text">3.1 Hợp tác xã</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}TuVan" title="Tư vấn">
                              <i class="bi bi-gear"></i>
                              <span class="menu-text">5.1 Tư vấn</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}GioiThieu" title="Giới thiệu">
                              <i class="bi bi bi-bank2"></i>
                              <span class="menu-text">9.1 Giới thiệu</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}HuongDanSuDung" title="Hướng dẫn sử dụng">
                              <i class="bi bi bi-bank2"></i>
                              <span class="menu-text">9.2 Hướng dẫn sử dụng</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}ThanhVienThongTin">
                              <i class="bi bi-shield-lock"></i>
                              <span class="menu-text">Đổi mật khẩu</span>
                        </a>
                  </li>
                  <li>
                        <a style="cursor: pointer;" (click)="Logout()">
                              <i class="bi bi-power"></i>
                              <span class="menu-text">Đăng xuất</span>
                        </a>
                  </li>
            </ul>
      </div>
</nav>
<div class="app-container">
      <router-outlet></router-outlet>
</div>
<br />
<br />
<br />
